import { useKeycloak } from '@react-keycloak/web';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';

export function Logout() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  const { setAuthUser } = useAuth();

  useEffect(() => {
    cleanUp();
  }, []);

  function cleanUp() {
    queryClient.clear();
    setAuthUser(undefined);
    setTimeout(() => {
      keycloak.logout();
    }, 1000);
  }
  return <Navigate to="/" />;
}
