import { Route, Routes } from 'react-router-dom';
import { Login } from '../../../pages/auth/login/Login';
import { Contact } from '../../../pages/landing/Contact';
import { FAQ } from '../../../pages/landing/FAQ';
import { Pricing } from '../../../pages/landing/Pricing';
import { SubcontractorRegistration } from '../../../pages/public/registration/SubcontractorRegistration';
import { LoginLayout } from '../LoginLayout';

const LandingRoutes = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path="login" element={<Login />} />
      <Route index element={<Login />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  </Routes>
);

export default LandingRoutes;
export const PublicRoutes = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path="sub" element={<SubcontractorRegistration />} />
    </Route>
  </Routes>
);
