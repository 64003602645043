import { useKeycloak } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { BuilderUserEntity, SubUserEntity } from 'sublinx-autopilot-shared';
import App from '../../../App';
import authUserObject from '../../../auth/AuthUser';
import { useAuth } from '../../../auth/useAuth';
import { ErrorsPage } from '../../../pages/error/Errors';
import UserService from '../../../services/UserService';
import { UserType } from '../../../utils/types';
import { SplashScreen } from '../SplashScreen';
import { SecuredRoutes } from './SecuredRoutes';
import AuthRoutes from './_authRoutes';
import LandingRoutes, { PublicRoutes } from './_landingRoutes';

const AppRoutes = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <SplashScreen />;
  }
  return (
    <Routes>
      <Route element={<App />}>
        <Route
          path="/*"
          element={
            <RequireAuth redirectTo="/landing">
              <SecuredRoutes />
            </RequireAuth>
          }
        />
        <Route path={'landing/*'} element={<LandingRoutes />} />
        <Route path="registration/*" element={<PublicRoutes />} />
        <Route path={'auth/*'} element={<AuthRoutes />} />
        <Route path={'error/*'} element={<ErrorsPage />} />
      </Route>
    </Routes>
  );
};
export default AppRoutes;

function RequireAuth({ children, redirectTo }: any) {
  const { keycloak } = useKeycloak();
  const { authUser, setAuthUser } = useAuth();
  const location = useLocation();
  if (keycloak.authenticated && authUser === undefined) {
    UserService()
      .users.fetchSelf()
      .then((user) => {
        let org = null;
        if (user.orgType === 'BUILDER') {
          org = (user.user as BuilderUserEntity).builderOrgEntity;
        } else {
          org = (user.user as SubUserEntity).subOrgEntity;
        }
        const sublinxAuthUser: UserType = {
          ...user,
          org,
          keycloakToken: keycloak.tokenParsed,
          isSublinxAdmin:
            keycloak.tokenParsed?.realm_access?.roles.includes('SUBLINX_ADMIN') || false,
          isBuilder: user.orgType === 'BUILDER',
          isSub: user.orgType === 'SUB',
        };
        authUserObject.user = user as UserType;
        Object.freeze(authUserObject);
        setAuthUser(sublinxAuthUser);
        Sentry.setUser({
          email: sublinxAuthUser.user?.email,
          id: sublinxAuthUser.user?.id?.toString(),
        });
      });
  }
  return keycloak.authenticated ? (
    children
  ) : (
    <Navigate state={{ from: location }} replace to={redirectTo} />
  );
}
