import {
  ActionIcon,
  Anchor,
  AppShell,
  Burger,
  Container,
  Group,
  MantineProvider,
  Paper,
  Transition,
  useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useKeycloak } from '@react-keycloak/web';
import { IconLogin, IconMoonStars, IconSun } from '@tabler/icons-react';
import cx from 'clsx';
import React, { FC } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import { Logo } from './_logo';

import classes from './LoginLayout.module.css';

interface FooterCenteredProps {
  links: { link: string; label: string }[];
}

export function FooterCentered({ links }: FooterCenteredProps) {
  const location = useLocation();
  const items = links.map((link) => (
    <Anchor
      component={Link}
      to={link.link}
      color={location.pathname === link.link ? 'light' : 'dimmed'}
      key={link.label}
      style={{ lineHeight: 1 }}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <AppShell.Footer hiddenFrom="sm" h={70}>
      <div className={classes.inner}>
        <Logo width={200} />

        <Group className={classes.footerLinks}>{items}</Group>
      </div>
    </AppShell.Footer>
  );
}

interface HeaderSimpleProps {
  links: { link: string; label: string }[];
}

export function HeaderSimple({ links }: HeaderSimpleProps) {
  const { keycloak } = useKeycloak();
  const [opened, handlers] = useDisclosure(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const location = useLocation();
  const items = links.map((link) => (
    <Anchor
      component={Link}
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: location.pathname === link.link })}
      onClick={handlers.close}
    >
      {link.label}
    </Anchor>
  ));

  return (
    <AppShell.Header mb={40}>
      <Container className={classes.header}>
        <Group wrap="nowrap">
          <NavLink to="/">
            <Logo height={50} />
          </NavLink>
          <ActionIcon variant="default" ml="sm" onClick={() => toggleColorScheme()} size={30}>
            {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
          </ActionIcon>
        </Group>
        <Group wrap="nowrap" gap={5} className={classes.links}>
          {items}
          <ActionIcon variant="default" ml="sm" onClick={() => keycloak.login({})} size={30}>
            <IconLogin size={16} />
          </ActionIcon>
        </Group>

        <Burger
          opened={opened}
          onClick={() => handlers.toggle()}
          className={classes.burger}
          size="sm"
        />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </AppShell.Header>
  );
}
const links = [
  { link: '/landing', label: 'Home' },
  { link: '/landing/pricing', label: 'Pricing' },
  { link: '/landing/faq', label: 'FAQ' },
  { link: '/landing/contact', label: 'Contact' },
];
interface LayoutProps {
  children?: React.ReactNode;
}
export const LoginLayout: FC<LayoutProps> = () => (
  // const { colorScheme } = useMantineColorScheme();
  <>
    <MantineProvider
    // theme={{
    //   colorScheme,
    //   components: {
    //     AppShell: {
    //       styles: (theme) => ({
    //         root: {
    //           backgroundColor: colorScheme === 'dark' ? theme.colors.dark[9] : '#F5F8FA',
    //         },
    //       }),
    //     },
    //     Paper: {
    //       defaultProps: {
    //         p: 'md',
    //         shadow: 'sm',
    //       },
    //       styles: (theme) => ({
    //         root: {
    //           backgroundColor:
    //             colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white,
    //         },
    //       }),
    //     },
    //     ScrollArea: {
    //       defaultProps: {
    //         pb: 'lg',
    //       },
    //     },
    //     Card: {
    //       defaultProps: {
    //         p: 'md',
    //         shadow: 'sm',
    //       },
    //       styles: (theme) => ({
    //         root: {
    //           backgroundColor:
    //             colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.white,
    //         },
    //       }),
    //     },
    //   },
    // }}
    >
      <AppShell
        // fixed
        header={{
          height: '80px',
        }}
      >
        <HeaderSimple links={links} />
        <AppShell.Main>
          <Container pt="md">
            <Outlet />
          </Container>
        </AppShell.Main>
        <FooterCentered links={links} />
      </AppShell>
    </MantineProvider>
  </>
);
