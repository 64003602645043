import { Alert, Button, Center, Loader, Stack, Text } from '@mantine/core';
import { AxiosError } from 'axios';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface SuspenseLoaderProps {
  isError?: boolean;
  message?: string;
  queryError?: AxiosError;
}

export const SuspenseLoader: FC<SuspenseLoaderProps> = (props) => {
  const { isError = false, message = 'Loading...', queryError } = props;
  const navigate = useNavigate();
  return (
    <Center>
      <Stack align="center" mt="xl">
        {isError === false ? (
          <>
            <Loader size="lg" type="bars" />
            <Text c="dimmed" size="lg">
              {message}
            </Text>
          </>
        ) : (
          <>
            <Alert color="red">
              {queryError?.message} An error has occurred, please try again.
            </Alert>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </>
        )}
      </Stack>
    </Center>
  );
};
