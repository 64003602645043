import { Center, Loader, Stack } from '@mantine/core';
import { Logo } from './_logo';

export const SplashScreen = () => (
  <Center style={{ height: '50vh' }}>
    <Stack>
      <Logo width={265} />
      <Center>
        <Loader />
      </Center>
    </Stack>
  </Center>
);
