import { Image, useMantineColorScheme } from '@mantine/core';
import darkLogo from '../../assets/images/autopilot-logo-dark.svg';
import lightLogo from '../../assets/images/autopilot-logo-light.svg';
import logoOnly from '../../assets/images/autopilot-logo-only.png';

export function Logo({
  height,
  width,
  darkTheme,
  iconOnly = false,
}: {
  height?: number;
  width?: number;
  darkTheme?: boolean;
  iconOnly?: boolean;
}) {
  const { colorScheme } = useMantineColorScheme();
  return iconOnly ? (
    <Image {...(width && { width })} {...(height && { height })} src={logoOnly} alt="Sublinx" />
  ) : (
    <Image
      {...(width && { width })}
      {...(height && { height })}
      src={darkTheme ? darkLogo : colorScheme === 'dark' ? darkLogo : lightLogo}
      alt="Sublinx"
    />
  );
}
