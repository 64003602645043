import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  List,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { FC, useState } from 'react';

interface PricingCardProps {
  id: string;
  title: string;
  description: string;
  price: string;
  active: boolean;
  setActive: (selected: string) => void;
  list: PricingCardListProps[];
}
interface PricingCardListProps {
  title: string;
  included: boolean;
}

const PricingCard = (props: PricingCardProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { title, description, list, active, setActive, id, price } = props;
  return (
    <Card
      withBorder
      radius="md"
      p="md"
      shadow={active ? 'xl' : 'xs'}
      py={active ? 30 : 'md'}
      style={{
        transition: 'padding-top .2s ease-in-out, padding-bottom .2s ease-in-out',
        backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      }}
    >
      <Title ta="center">{title}</Title>
      <Card.Section
        style={{
          borderBottom: `1px solid ${
            colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
          }`,
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          paddingBottom: theme.spacing.md,
        }}
        mt="md"
      >
        <Stack>
          {id === 'STARTUP' ? (
            <Badge color="red" size="sm">
              Support NOT Included
            </Badge>
          ) : (
            <Badge color="green" size="sm">
              Support Included
            </Badge>
          )}
        </Stack>
        <Text size="sm" mt="xs" ta="center">
          {description}
        </Text>
      </Card.Section>
      <Card.Section
        style={{
          borderBottom: `1px solid ${
            colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
          }`,
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          paddingBottom: theme.spacing.md,
        }}
        mt="md"
      >
        <Group justify="space-between">
          <Text size="xl" ta="center" fw="bold" style={{ lineHeight: 1 }}>
            {price}&cent;
          </Text>
          <Text size="sm" ta="center" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
            per Incoming Purchase Order
          </Text>
        </Group>
      </Card.Section>

      <Card.Section
        style={{
          borderBottom: `1px solid ${
            colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
          }`,
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          paddingBottom: theme.spacing.md,
        }}
      >
        <Text
          mt="md"
          style={{ textTransform: 'uppercase', fontSize: theme.fontSizes.xs, fontWeight: 700 }}
          c="dimmed"
        >
          Perfect for you, if you enjoy
        </Text>
        <Group gap={7} mt={5}>
          <List
            spacing="md"
            size="sm"
            center
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <IconCircleCheck size={16} />
              </ThemeIcon>
            }
          >
            {list.map((item) => (
              <List.Item
                ml={5}
                mt="xs"
                {...(!item.included && {
                  icon: (
                    <ThemeIcon color="orange" size={24} radius="xl">
                      <IconCircleX size={16} />
                    </ThemeIcon>
                  ),
                })}
                key={item.title}
              >
                {item.title}
              </List.Item>
            ))}
          </List>
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button
          style={{
            transition: 'padding-top .2s linear, padding-bottom .2s linear',
            flex: 1,
          }}
          radius="md"
          mt={active ? 20 : 0}
          variant={active ? 'filled' : 'light'}
          onClick={() => setActive(id)}
        >
          {active ? 'Selected' : 'Select'}
        </Button>
      </Group>
    </Card>
  );
};

export const Pricing: FC = () => {
  const [active, setActive] = useState('ADVANCED');
  return (
    <>
      <Paper radius="md" p="lg" pb={50}>
        <Stack align="center" mb="xl">
          <Title>Choose Your Plan</Title>
          <Text color="dimmied" size="xl">
            Choose the plan that fits your needs
          </Text>
        </Stack>
        <Grid align="center">
          <Grid.Col span={{ md: 4 }}>
            {/* Pricing Card */}
            <PricingCard
              price="6"
              id="STARTUP"
              setActive={setActive}
              active={active === 'STARTUP'}
              title="Startup"
              description="Great for small businesses"
              list={[
                { title: 'Up to 10 Active Users', included: true },
                { title: 'Up to 30 Active Projects', included: true },
                { title: 'Automatic Accounting', included: true },
                { title: 'Supply Pro Integration', included: false },
                { title: 'Automatic Work Order Creation', included: false },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ md: 4 }}>
            <PricingCard
              price="8"
              id="ADVANCED"
              setActive={setActive}
              active={active === 'ADVANCED'}
              title="Advanced"
              description="Great for medium sized businesses"
              list={[
                { title: 'Up to 30 Active Users', included: true },
                { title: 'Up to 60 Active Projects', included: true },
                { title: 'Automatic Accounting', included: true },
                { title: 'Supply Pro Integration', included: true },
                { title: 'Automatic Work Order Creation', included: false },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ md: 4 }}>
            <PricingCard
              price="10"
              id="ENTERPRISE"
              setActive={setActive}
              active={active === 'ENTERPRISE'}
              title="Enterprise"
              description="Great for large businesses"
              list={[
                { title: 'Unlimited Active Users', included: true },
                { title: 'Unlimited Active Projects', included: true },
                { title: 'Automatic Accounting', included: true },
                { title: 'Supply Pro Integration', included: true },
                { title: 'Automatic Work Order Creation', included: true },
              ]}
            />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
};
