import { FC } from 'react';
import { useAuth } from '../../../auth/useAuth';
import { UserType } from '../../../utils/types';
import { SplashScreen } from '../SplashScreen';
import BuilderRoutes from './_builderRoutes';
import SubcontractorRoutes from './_subcontractorRoutes';

export const SecuredRoutes: FC = () => {
  const { authUser } = useAuth();
  if (authUser) {
    return fetchRoute(authUser);
  }
  return <SplashScreen />;
};
// const MemoizedAppRoutes = React.memo(fetchRoute);
function fetchRoute(authUser: UserType) {
  if (authUser.orgType === 'BUILDER') {
    return <BuilderRoutes />;
  }
  if (authUser.orgType === 'SUB') {
    return <SubcontractorRoutes />;
  }

  return null;
}
