import { Anchor, Box, Breadcrumbs, Card, Divider, Group, Text, Title } from '@mantine/core';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

const appTitle = import.meta.env.VITE_APP_TITLE;
const PageHeader = ({
  title,
  subTitle,
  subTitleComponent,
  right,
  children,
  hasTabs,
  inline = false,
  visible = true,
  breadcrumbs,
}: {
  title?: string;
  subTitle?: string;
  subTitleComponent?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
  hasTabs?: boolean;
  inline?: boolean;
  visible?: boolean;
  breadcrumbs?: { title: string; href: string }[];
}) =>
  visible ? (
    <>
      <Card mb="lg">
        <Card.Section withBorder inheritPadding py="sm">
          <Group justify="space-between">
            <Title order={4} mb={0}>
              {title}
              {subTitle && (
                <Text c="dimmed" size="sm" fw="semibold">
                  {subTitle}
                </Text>
              )}
              {subTitleComponent}
              {breadcrumbs && (
                <Breadcrumbs fz="sm">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Fragment key={index}>
                      {breadcrumb.href === '#' ? (
                        <Text
                          size="sm"
                          c="dimmed"
                          key={breadcrumb.title}
                          style={{ marginRight: 5 }}
                        >
                          {breadcrumb.title}
                        </Text>
                      ) : (
                        <Anchor
                          size="sm"
                          component={NavLink}
                          key={breadcrumb.title}
                          to={breadcrumb.href}
                        >
                          {breadcrumb.title}
                        </Anchor>
                      )}
                    </Fragment>
                  ))}
                </Breadcrumbs>
              )}
            </Title>
            {right && (
              <Box style={{ alignSelf: 'flex-start' }}>
                <Group align="center">{right}</Group>
              </Box>
            )}
          </Group>
        </Card.Section>
        {children && (
          <Card.Section pt="xs" px="xs" pb={hasTabs ? 0 : 'xs'}>
            {children}
          </Card.Section>
        )}
      </Card>
      {inline && <Divider mt={0} mb="sm" />}
    </>
  ) : null;

export const usePageData = (props: {
  isPageLoading: boolean;
  pageTitle?: string;
  defaultSelectedTab?: string;
}) => {
  const [title, setTitle] = useState<string>(props.pageTitle || appTitle);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(props.isPageLoading || false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { authUser } = useAuth();
  const selectedTab = searchParams.get('t') || props.defaultSelectedTab;
  const setSelectedTab = (tab: string | null) => {
    if (tab) {
      setSearchParams({ t: tab });
    }
  };

  useEffect(() => {
    if (title) {
      document.title = `${appTitle} - ${title}`;
    }
  }, [title]);

  return {
    title,
    setTitle,
    isPageLoading,
    setIsPageLoading,
    PageHeader,
    t,
    i18n,
    navigate,
    authUser,
    selectedTab,
    setSelectedTab,
  };
};
