import axios from 'axios';
import {
  BuilderOrgEntity,
  BuilderUserEntity,
  BuilderUserGist,
  BuilderUserPatch,
  SubOrgEntity,
  SubUserEntity,
  SubUserGist,
  SubUserPatch,
  UserDto,
  UserPermissionEntity,
  UserRoleEntity,
  UserRolePatch,
  UserSettingEntity,
} from 'sublinx-autopilot-shared';
import authUserObject from '../auth/AuthUser';
import { OptionalId } from '../utils/types';

type User = BuilderUserEntity | SubUserEntity;

const UserService = () => {
  let orgUrlType: string;
  if (authUserObject.user) {
    orgUrlType = authUserObject.user?.orgType === 'BUILDER' ? 'builders' : 'subs';
  }
  return {
    users: {
      async fetchSubUsersGist(): Promise<SubUserGist[]> {
        const response = await axios.get('/common/subs/users/gists');
        return response.data;
      },
      async fetchBuilderUsersGist(): Promise<BuilderUserGist[]> {
        const response = await axios.get('/common/builders/users/gists');
        return response.data;
      },

      async fetchSelf(): Promise<UserDto> {
        const response = await axios.get('/common/users/authenticated');
        return response.data;
      },
      async fetchAll({
        enabled = true,
      }: {
        enabled?: boolean;
      }): Promise<SubUserEntity[] | BuilderUserEntity[]> {
        const response = await axios.get(`/common/${orgUrlType}/users`, {
          params: { enabled },
        });
        return response.data;
      },
      async fetchById(id: number | string): Promise<SubUserEntity> {
        const response = await axios.get(`/common/${orgUrlType}/users/${id}`);
        return response.data;
      },
      async save(user: OptionalId<SubUserPatch>): Promise<SubUserEntity> {
        let response;
        if (user.id) {
          response = await axios.patch(`/common/${orgUrlType}/users/${user.id}`, user);
        } else {
          response = await axios.post(`/common/${orgUrlType}/users`, user);
        }

        return response.data;
      },
      async delete(id: number | string): Promise<void> {
        const response = await axios.delete(`/common/${orgUrlType}/users/${id}`);
        return response.data;
      },
      async enable(id: number | string, enabled: boolean): Promise<SubUserEntity> {
        const response = await axios.patch(`/common/${orgUrlType}/users/${id}`, { enabled });

        return response.data;
      },
      async saveRolesToUser(
        user: OptionalId<User>,
        roleIds: number[] | string[] | undefined
      ): Promise<User> {
        const response = await axios.post(`/common/users/${user.id}/roles`, roleIds);
        return response.data;
      },
      settings: {
        async fetchAllByName(name: string): Promise<UserSettingEntity[]> {
          const response = await axios.get(`/common/users/authenticated/settings?code=${name}`);
          return response.data;
        },
        async save(
          code: string,
          name: string,
          jsonData: string,
          id?: number
        ): Promise<UserSettingEntity> {
          if (id) {
            const response = await axios.patch(`/common/users/authenticated/settings/${id}`, {
              code,
              name,
              jsonData,
            });
            return response.data;
          }
          const response = await axios.post('/common/users/authenticated/settings', {
            code,
            name,
            jsonData,
          });
          return response.data;
        },
        async delete(id: number | string): Promise<void> {
          const response = await axios.delete(`/common/users/authenticated/settings/${id}`);
          return response.data;
        },
      },
    },
    roles: {
      async fetchByUserId(id: number | string): Promise<UserRoleEntity[]> {
        const response = await axios.get(`/common/${orgUrlType}/users/${id}/roles`);
        return response.data;
      },
      async fetchAll(): Promise<UserRoleEntity[]> {
        const response = await axios.get('/common/users/roles');
        return response.data;
      },
      async fetchById(id: number | string): Promise<UserRoleEntity> {
        const response = await axios.get(`/common/users/roles/${id}`);
        return response.data;
      },
      async save(role: OptionalId<UserRolePatch>): Promise<UserRoleEntity> {
        let response;
        if (role.id) {
          response = await axios.patch(`/common/users/roles/${role.id}`, role);
        } else {
          response = await axios.post('/common/users/roles', role);
        }

        return response.data;
      },
      async delete(id: number | string): Promise<void> {
        const response = await axios.delete(`/common/users/roles/${id}`);
        return response.data;
      },
      permissions: {
        async fetchAll(): Promise<UserPermissionEntity[]> {
          const response = await axios.get('/common/users/roles/permissions');
          return response.data;
        },
      },
    },
    adminUsers: {
      getOrgTypeUrl(orgType2?: string): string {
        return orgType2 === 'BUILDER' ? 'builders' : 'subs';
      },
      async save(
        org: SubOrgEntity | BuilderOrgEntity,
        user: OptionalId<BuilderUserPatch | SubUserPatch>
      ): Promise<BuilderUserEntity | SubUserEntity> {
        let response;
        if (user.id) {
          response = await axios.patch(
            `/common/admin/${this.getOrgTypeUrl(org.type)}/users/${user.id}`,
            user
          );
        } else {
          response = await axios.post(
            `/common/admin/${this.getOrgTypeUrl(org.type)}/${org.id}/users`,
            user
          );
        }

        return response.data;
      },
      async fetchAll(
        org: SubOrgEntity | BuilderOrgEntity
      ): Promise<BuilderUserEntity[] | SubUserEntity[]> {
        const response = await axios.get(
          `/common/admin/${this.getOrgTypeUrl(org.type)}/${org.id}/users`
        );
        return response.data;
      },
      async fetchRolesByUser(user: SubUserEntity & BuilderUserEntity): Promise<UserRoleEntity[]> {
        const org = user.builderOrgEntity ? user.builderOrgEntity : user.subOrgEntity;
        const response = await axios.get(
          `/common/${this.getOrgTypeUrl(org!.type)}/users/${user!.id}/roles`
        );
        return response.data;
      },
      async fetchById(
        org: SubOrgEntity | BuilderOrgEntity,
        id: number | string
      ): Promise<BuilderUserEntity | SubUserEntity> {
        const response = await axios.get(
          `/common/admin/${this.getOrgTypeUrl(org.type)}/users/${id}`
        );
        return response.data;
      },

      async delete(id: number | string, org: SubOrgEntity | BuilderOrgEntity): Promise<void> {
        const response = await axios.delete(
          `/common/admin/${this.getOrgTypeUrl(org.type)}/users/${id}`
        );
        return response.data;
      },
    },
    utils: {
      isSelf(userId: number): boolean {
        return authUserObject.user?.user?.id === userId;
      },
    },
  };
};

export default UserService;
