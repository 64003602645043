import { createContext, useContext, useMemo, useState } from 'react';
import { UserType } from '../utils/types';

interface AuthContextProps {
  authUser: UserType | undefined;
  setAuthUser: (user: UserType | undefined) => void;
}
const AuthContext = createContext<AuthContextProps>({ authUser: undefined, setAuthUser: () => {} });

export const AuthProvider = ({ children }: any) => {
  const [authUser, setAuthUser] = useState<UserType | undefined>();

  const value = useMemo(
    () => ({
      authUser,
      setAuthUser,
    }),
    [authUser, setAuthUser]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
