import axios, { AxiosStatic } from 'axios';

export * from './services/FileService';
export * from './services/JobService';
export * from './services/OrgService';
export * from './services/PlanService';
export * from './services/ProjectService';
export * from './services/ScheduleService';
export * from './services/StageService';
export * from './services/TradeService';
export * from './types/custom-types';
export * from './types/open-api/types';
export * from './utils/DataFormattingUtils';
export * from './utils/DataUtils';

export function setupSharedAxios(incomingAxios: AxiosStatic) {
  axios.defaults = incomingAxios.defaults;
  axios.interceptors = incomingAxios.interceptors;
}
