import { SpotlightActionData } from "@mantine/spotlight";
import { leftIndentProperty } from "@syncfusion/ej2/documenteditor";
import axios from "axios";
import { GlobalSearchDto } from "sublinx-autopilot-shared";

const SpotlightService = {
  fetchSpotlightActions: async (
    searchText: string,
  ): Promise<GlobalSearchDto> => {
    const response = await axios.get("/common/global/search", {
      params: {
        searchText,
      },
    });
    return response.data;
  },
};

export default SpotlightService;
