import {
  ComboboxItem,
  OptionsFilter,
  Select,
  SimpleGrid,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FC } from 'react';
import * as Yup from 'yup';
import { stateSelectOptions } from '../../utils/FormTypes';

interface StreetAddressFormComponentsProps {
  form: UseFormReturnType<any>;
  isLoading: boolean;
  required?: boolean;
  regionOnly?: boolean;
  disabled?: boolean;
}

export const addressShape = Yup.object().shape({
  street1: Yup.string().required('Street is required'),
  street2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  postalCode: Yup.string().required('Zip is required'),
});

const stateFilter: OptionsFilter = ({ options, search }) => {
  const splittedSearch = search.toLowerCase().trim().split(' ');
  return (options as ComboboxItem[]).filter((option) => {
    const words = option.label.toLowerCase().trim().split(' ');
    return splittedSearch.every((searchWord) => words.some((word) => word.includes(searchWord)));
  });
};

export const StreetAddressFormComponents: FC<StreetAddressFormComponentsProps> = ({
  form,
  isLoading,
  required,
  regionOnly = false,
  disabled = false,
}) => (
  <>
    <Title order={5}>Address</Title>
    <Stack gap="sm">
      {!regionOnly && (
        <SimpleGrid mt={0} cols={{ base: 2, sm: 1, md: 2 }}>
          <TextInput
            data-testid="street-1-input"
            disabled={isLoading || disabled}
            required={required}
            label="Street Address 1"
            placeholder="Enter address 1"
            {...form.getInputProps('address.street1')}
          />
          <TextInput
            data-testid="street-2-input"
            disabled={isLoading || disabled}
            label="Street Address 2"
            placeholder="Enter address 2"
            {...form.getInputProps('address.street2')}
          />
        </SimpleGrid>
      )}
      <SimpleGrid cols={{ base: 3, sm: 1, md: 3 }}>
        <TextInput
          data-testid="city-input"
          disabled={isLoading || disabled}
          required={required}
          label="City"
          placeholder="Enter city"
          {...form.getInputProps('address.city')}
        />
        <Select
          data-testid="state-select"
          searchable
          data={stateSelectOptions}
          disabled={isLoading || disabled}
          autoComplete="off"
          filter={stateFilter}
          required={required}
          label="State"
          placeholder="Enter state"
          {...form.getInputProps('address.state')}
        />
        <TextInput
          data-testid="zip-input"
          disabled={isLoading || disabled}
          required={required}
          label="Zip"
          placeholder="Enter zip"
          {...form.getInputProps('address.postalCode')}
        />
      </SimpleGrid>
    </Stack>
  </>
);
