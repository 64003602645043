import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import keycloak from "../../../auth/keycloak";
import { MobileApp } from "../../../pages/admin/mobile/MobileApp";
import { Logout } from "../../../pages/auth/Logout";
import { ErrorPage404 } from "../../../pages/error/404";
import { Layout } from "../Layout";

const DashboardLazy = lazy(() =>
  import("../../../pages/dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  })),
);

const VisitsDashboardLazy = lazy(() =>
  import("../../../pages/common/visits/VisitsDashboard").then(
    ({ VisitsDashboard }) => ({
      default: VisitsDashboard,
    }),
  ),
);

const UserManageLazy = lazy(() =>
  import("../../../pages/common/users/manage/UserManage").then(
    ({ UserManage: UserProfile }) => ({
      default: UserProfile,
    }),
  ),
);
const UserListLazy = lazy(() =>
  import("../../../pages/common/users/list/UserList").then(
    async ({ UserList }) => ({
      default: UserList,
    }),
  ),
);
const RoleListLazy = lazy(() =>
  import("../../../pages/common/users/roles/RoleList").then(
    async ({ RoleList }) => ({
      default: RoleList,
    }),
  ),
);
const RoleManageLazy = lazy(() =>
  import("../../../pages/common/users/roles/RoleManage").then(
    async ({ RoleManage }) => ({
      default: RoleManage,
    }),
  ),
);
const ScheduleListLazy = lazy(() =>
  import("../../../pages/builder-pages/schedules/ScheduleList").then(
    async ({ ScheduleList }) => ({
      default: ScheduleList,
    }),
  ),
);
const ScheduleManageLazy = lazy(() =>
  import("../../../pages/builder-pages/schedules/ScheduleManage").then(
    async ({ ScheduleManage }) => ({
      default: ScheduleManage,
    }),
  ),
);
const ProjectListLazy = lazy(() =>
  import("../../../pages/builder-pages/projects/ProjectList").then(
    async ({ ProjectList }) => ({
      default: ProjectList,
    }),
  ),
);
const ProjectManageLazy = lazy(() =>
  import("../../../pages/builder-pages/projects/ProjectManage").then(
    async ({ ProjectManage }) => ({
      default: ProjectManage,
    }),
  ),
);
const PlanListLazy = lazy(() =>
  import("../../../pages/builder-pages/plans/PlanList").then(
    async ({ PlanList }) => ({
      default: PlanList,
    }),
  ),
);
const PlanManageLazy = lazy(() =>
  import("../../../pages/builder-pages/plans/PlanManage").then(
    async ({ PlanManage }) => ({
      default: PlanManage,
    }),
  ),
);
export const JobListLazy = lazy(() =>
  import("../../../pages/builder-pages/jobs/JobList").then(
    async ({ JobList }) => ({
      default: JobList,
    }),
  ),
);
export const JobListFilteredLazy = lazy(() =>
  import("../../../pages/builder-pages/jobs/JobListFiltered").then(
    async ({ JobListFiltered }) => ({
      default: JobListFiltered,
    }),
  ),
);
export const JobManageLazy = lazy(() =>
  import("../../../pages/builder-pages/jobs/JobManage").then(
    async ({ JobManage }) => ({
      default: JobManage,
    }),
  ),
);
export const JobDashboardLazy = lazy(() =>
  import("../../../pages/builder-pages/jobs/JobDashboard").then(
    async ({ JobDashboard }) => ({
      default: JobDashboard,
    }),
  ),
);
export const SubdivisionManageLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/projects/subdivisions/SubdivisionManage"
  ).then(async ({ SubdivisionManage }) => ({
    default: SubdivisionManage,
  })),
);
export const SubdivisionListLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/projects/subdivisions/SubdivisionList"
  ).then(async ({ SubdivisionList }) => ({
    default: SubdivisionList,
  })),
);
const StageListLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/schedules/ScheduleManage.StagesList"
  ).then(async ({ StagesList }) => ({
    default: StagesList,
  })),
);
const BuilderRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path={"/*"} element={<Navigate to="/" />} />
      <Route path="/" element={<DashboardLazy />} />
      <Route path="/visits" element={<VisitsDashboardLazy />} />
      <Route path="/schedules">
        <Route index element={<ScheduleListLazy />} />
        <Route path=":scheduleId" element={<ScheduleManageLazy />} />
        <Route path="new" element={<ScheduleManageLazy />} />
        <Route path="stages">
          <Route index element={<StageListLazy />} />
        </Route>
      </Route>
      <Route path="/jobs">
        <Route index element={<JobListLazy />} />
        <Route path="filtered" element={<JobListFilteredLazy />} />
        <Route path=":jobId" element={<JobManageLazy />} />
        <Route path="new" element={<JobManageLazy />} />
        <Route path="dashboard" element={<JobDashboardLazy />} />
      </Route>
      <Route path="/projects">
        <Route index element={<ProjectListLazy />} />
        <Route path=":projectId">
          <Route index element={<ProjectManageLazy />} />
          <Route path="subdivisions">
            <Route path=":subdivisionId" element={<SubdivisionManageLazy />} />
            <Route path="new" element={<SubdivisionManageLazy />} />
          </Route>
          <Route path="new" element={<ProjectManageLazy />} />
        </Route>
      </Route>
      <Route path="/subdivisions">
        <Route index element={<SubdivisionListLazy />} />
        <Route path=":subdivisionId" element={<SubdivisionManageLazy />} />
        <Route path="new" element={<SubdivisionManageLazy />} />
      </Route>
      <Route path="/plans">
        <Route index element={<PlanListLazy />} />
        <Route path=":planId" element={<PlanManageLazy />} />
        <Route path="new" element={<PlanManageLazy />} />
      </Route>
      <Route path="/users">
        <Route index element={<UserListLazy />} />
        <Route path=":userId" element={<UserManageLazy />} />
        <Route path="new" element={<UserManageLazy />} />
        <Route path="roles">
          <Route index element={<RoleListLazy />} />
          <Route path=":roleId" element={<RoleManageLazy />} />
          <Route path="new" element={<RoleManageLazy />} />
        </Route>
      </Route>
      <Route path="/organization">
        <Route path="subcontractors">
          <Route index element={<BuilderSubOrgListLazy />} />
          <Route path="new" element={<BuilderSubOrgManageLazy />} />
          <Route path=":id" element={<BuilderSubOrgManageLazy />} />
        </Route>
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<ErrorPage404 />} />
      {keycloak.realmAccess?.roles.includes("SUBLINX_ADMIN") && (
        <Route path="/admin">
          <Route path="mobile" element={<MobileApp />} />
          <Route path="subcontractors">
            <Route index element={<SubcontractorListLazy />} />
            <Route path="new" element={<SubcontractorManageLazy />} />
            <Route path=":id" element={<SubcontractorManageLazy />} />
          </Route>
          <Route path="builders">
            <Route index element={<BuilderListLazy />} />
            <Route path="new" element={<BuilderManageLazy />} />
            <Route path=":id" element={<BuilderManageLazy />} />
          </Route>
          <Route path="trades">
            <Route index element={<TradeListLazy />} />
            <Route path="new" element={<TradeManageLazy />} />
            <Route path=":id" element={<TradeManageLazy />} />
          </Route>
          <Route path="tasks">
            <Route index element={<TradeTaskReorderLazy />} />
          </Route>
          <Route path="users">
            <Route index element={<AdminUserListLazy />} />
          </Route>
        </Route>
      )}
    </Route>
  </Routes>
);
export default BuilderRoutes;

const SubcontractorListLazy = lazy(() =>
  import("../../../pages/admin/subcontractors/list/SubcontractorList").then(
    async ({ SubcontractorList }) => ({ default: SubcontractorList }),
  ),
);
const SubcontractorManageLazy = lazy(() =>
  import("../../../pages/admin/subcontractors/manage/SubcontractorManage").then(
    async ({ SubcontractorManage }) => ({ default: SubcontractorManage }),
  ),
);

const BuilderSubOrgListLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/organization/subcontractors/SubcontractorList"
  ).then(async ({ BuilderSubcontractorList }) => ({
    default: BuilderSubcontractorList,
  })),
);
const BuilderSubOrgManageLazy = lazy(() =>
  import(
    "../../../pages/builder-pages/organization/subcontractors/SubcontractorManage"
  ).then(async ({ BuilderSubcontractorManage }) => ({
    default: BuilderSubcontractorManage,
  })),
);
const BuilderListLazy = lazy(() =>
  import("../../../pages/admin/builders/list/BuilderList").then(
    async ({ BuilderList }) => ({
      default: BuilderList,
    }),
  ),
);
const BuilderManageLazy = lazy(() =>
  import("../../../pages/admin/builders/manage/BuilderManage").then(
    async ({ BuilderManage }) => ({
      default: BuilderManage,
    }),
  ),
);
const TradeListLazy = lazy(() =>
  import("../../../pages/admin/trades/list/TradeList").then(
    async ({ TradeList }) => ({
      default: TradeList,
    }),
  ),
);
const TradeManageLazy = lazy(() =>
  import("../../../pages/admin/trades/manage/TradeManage").then(
    async ({ TradeManage }) => ({
      default: TradeManage,
    }),
  ),
);
const TradeTaskReorderLazy = lazy(() =>
  import("../../../pages/admin/tasks/manage/TradeTaskReorder").then(
    async ({ TradeTaskReorder }) => ({
      default: TradeTaskReorder,
    }),
  ),
);

const AdminUserListLazy = lazy(() =>
  import("../../../pages/admin/users/KeycloakUserList").then(
    async ({ KeycloakUserList }) => ({
      default: KeycloakUserList,
    }),
  ),
);
