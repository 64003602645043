import { Button, Container, Image, SimpleGrid, Text, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import image from '../../assets/images/errors/searching.svg';

export function NotFoundImage() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  return (
    <Container style={{ paddingTop: 80, paddingBottom: 80 }}>
      <SimpleGrid spacing={80} cols={{ sm: 1 }}>
        <Image src={image} hiddenFrom="sm" />
        <div>
          <Title
            style={{
              fontWeight: 900,
              fontSize: 34,
              marginBottom: theme.spacing.md,
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            }}
          >
            Something is not right...
          </Title>
          <Text c="dimmed" size="lg">
            Page you are trying to open does not exist. You may have mistyped the address, or the
            page has been moved to another URL. If you think this is an error contact support.
          </Text>
          <Button variant="outline" size="md" mt="xl" onClick={() => navigate('/')}>
            Get back to dashboard
          </Button>
        </div>
        <Image src={image} />
      </SimpleGrid>
    </Container>
  );
}

export const ErrorPage404 = () => <NotFoundImage />;
