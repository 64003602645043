import { Route, Routes } from 'react-router-dom';
import { Login } from '../../../pages/auth/login/Login';
import { LoginLayout } from '../LoginLayout';

const AuthRoutes = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path="login" element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export default AuthRoutes;
