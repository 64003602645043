import {
  ActionIcon,
  AppShell,
  Burger,
  Group,
  MantineTheme,
  Stack,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { openSpotlight } from '@mantine/spotlight';
import { IconLogout, IconMoonStars, IconSearch, IconSun } from '@tabler/icons-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';

interface HeaderBarProps {
  toggleColorScheme: any;
  opened: boolean;
  handlers: any;
  theme: MantineTheme;
  desktopCollapsed: boolean;
  desktopHandlers: any;
}
export const HeaderBar: FC<HeaderBarProps> = (props) => {
  const { toggleColorScheme, opened, handlers, theme } = props;
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  return (
    <AppShell.Header>
      <Group grow justify="space-between" gap="xs" px="sm" h="100%">
        <Burger
          hiddenFrom="sm"
          size="sm"
          opened={opened}
          onClick={() => handlers.toggle()}
          color={theme.colors.gray[6]}
        />
        <Stack gap={0} visibleFrom="sm">
          <Group>
            <span>
              <Text size="xl">{authUser?.org?.name}</Text>
              <Text size="md" c="dimmed">
                {authUser?.org?.address?.city} Division
              </Text>
            </span>
          </Group>
        </Stack>
        <Group
          wrap="nowrap"
          style={{ height: '100%', justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        >
          <ActionIcon variant="default" onClick={() => openSpotlight()} size={30}>
            <IconSearch size={16} />
          </ActionIcon>
          {/* <Popover width={350} position="bottom-end" withArrow shadow="md">
            <Popover.Target>
              <ActionIcon variant="default" size={30}>
                <IconCloud size={16} />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown p="xs">
              <WeatherWidget />
            </Popover.Dropdown>
          </Popover> */}
          <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
            {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
          </ActionIcon>
          <ActionIcon variant="default" onClick={() => navigate('/logout')} size={30}>
            <IconLogout size={16} />
          </ActionIcon>
        </Group>
      </Group>
      {/* </Paper> */}
    </AppShell.Header>
  );
};
