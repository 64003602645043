import { Route, Routes } from 'react-router-dom';
import { Layout } from '../../components/layout/Layout';
import { ErrorPage404 } from './404';

const ErrorsPage = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="404" element={<ErrorPage404 />} />
      {/* <Route path="500" element={<Error500 />} /> */}
      <Route index element={<ErrorPage404 />} />
    </Route>
  </Routes>
);

export { ErrorsPage };
