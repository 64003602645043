import { Card, Text } from '@mantine/core';
import { FC, useEffect } from 'react';
import { PageWrapper } from '../../../components/layout/PageWrapper';
import { usePageData } from '../../../hooks/usePageData';

export const MobileApp: FC = () => {
  const { setIsPageLoading: setIsLoading, PageHeader } = usePageData({
    isPageLoading: true,
    pageTitle: 'Trades',
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <PageWrapper>
      <PageHeader title="Mobile App" subTitle="View and test the mobile app" />
      <Card style={{ width: 500 }}>
        <Card.Section withBorder inheritPadding py="md" mb={0}>
          <Text fw="bold">Mobile App</Text>
        </Card.Section>
        <Card.Section style={{ height: 800 }}>
          <object
            width="100%"
            height="100%"
            data="https://autopilot-mobileapp-dot-sublinx-autopilot-qa.uc.r.appspot.com"
          >
            Mobile App
          </object>
        </Card.Section>
      </Card>
    </PageWrapper>
  );
};
