import {
  Box,
  Button,
  Card,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconAt, IconMapPin, IconPhone, IconSun } from '@tabler/icons-react';
import cx from 'clsx';
import React from 'react';

type ContactIconVariant = 'white' | 'gradient';

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
  variant?: ContactIconVariant;
}

function ContactIcon({
  icon: Icon,
  title,
  description,
  variant = 'gradient',
  className,
  ...others
}: ContactIconProps) {
  const theme = useMantineTheme();
  return (
    <div
      className={cx(
        {
          wrapper: {
            display: 'flex',
            alignItems: 'center',
            color: theme.white,
          },
        },
        className
      )}
      {...others}
    >
      {variant === 'gradient' ? (
        <ThemeIcon
          size={40}
          radius="md"
          style={{
            marginRight: theme.spacing.md,
            backgroundImage:
              variant === 'gradient'
                ? `linear-gradient(135deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
                    theme.colors[theme.primaryColor][6]
                  } 100%)`
                : 'none',
            backgroundColor: 'transparent',
          }}
        >
          <Icon size={24} />
        </ThemeIcon>
      ) : (
        <Box mr="md">
          <Icon size={24} />
        </Box>
      )}

      <div>
        <Text
          size="xs"
          style={{
            color:
              variant === 'gradient' ? theme.colors.gray[6] : theme.colors[theme.primaryColor][0],
          }}
        >
          {title}
        </Text>
        <Text style={{ color: variant === 'gradient' ? theme.black : theme.white }}>
          {description}
        </Text>
      </div>
    </div>
  );
}

interface ContactIconsListProps {
  data?: ContactIconProps[];
  variant?: ContactIconVariant;
}

const MOCKDATA = [
  { title: 'Email', description: 'info@sublinx.com', icon: IconAt },
  { title: 'Phone', description: '+1 (800) 555-5555', icon: IconPhone },
  { title: 'Address', description: 'Santa Fe Dr', icon: IconMapPin },
  { title: 'Working hours', description: '8 a.m. – 11 p.m.', icon: IconSun },
];

export function ContactIconsList({ data = MOCKDATA, variant }: ContactIconsListProps) {
  const items = data.map((item, index) => <ContactIcon key={index} variant={variant} {...item} />);
  return <Stack>{items}</Stack>;
}

export function ContactIcons() {
  return <ContactIconsList variant="white" />;
}
export function ContactUs() {
  return (
    <Paper p="xl">
      <SimpleGrid cols={{ sm: 1 }} spacing={50}>
        <Stack mb="xl">
          <Title>Contact Us</Title>
          <Text color="dimmied" size="xl">
            Get in touch with us, we&apos;d love to hear from you.
          </Text>
          <ContactIcons />
        </Stack>
        <Card>
          <TextInput label="Email" placeholder="Enter Email" required />
          <TextInput label="Name" placeholder="Enter Name" mt="md" />
          <Textarea required label="Message" placeholder="Enter your message" minRows={4} mt="md" />

          <Group justify="flex-end" mt="md">
            <Button>Send message</Button>
          </Group>
        </Card>
      </SimpleGrid>
    </Paper>
  );
}

export const Contact = ContactUs;
