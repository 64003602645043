import {
  AppShell,
  Code,
  Group,
  NavLink,
  ScrollArea,
  Stack,
  ThemeIcon,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconBuildingSkyscraper,
  IconCalendar,
  IconChartTreemap,
  IconHome,
  IconHomeSearch,
  IconLayoutDashboard,
  IconMapPin,
  IconSettings,
  IconUser,
  IconUsersGroup,
} from "@tabler/icons-react";
import { Link, matchPath, useLocation } from "react-router-dom";
import keycloak from "../../auth/keycloak";
import { useAuth } from "../../auth/useAuth";
import i18n from "../../i18n/i18n";
import { UserType } from "../../utils/types";
import { UserButton } from "./UserButton";
import { Logo } from "./_logo";

const version = import.meta.env.VITE_APP_VERSION;
const environment = import.meta.env.VITE_APP_ENVIRONMENT;

export interface NavbarLinksProps {
  icon?: React.ReactNode | any;
  label: string;
  description?: string;
  href?: string;
  links?: NavbarLinksProps[];
  routes?: string[];
  color?: string;
  id?: string;
  expanded?: boolean;
}

const ICON_SIZE = 22;
export function NavbarNested({
  opened,
  // handlers,
  desktopCollapsed,
  // desktopHandlers,
  isMobile,
}: {
  opened: boolean;
  handlers: any;
  desktopCollapsed: boolean;
  desktopHandlers: any;
  isMobile: boolean;
}) {
  const location = useLocation();
  const { authUser } = useAuth();
  const navBarLinks = fetchNavBarLinks(authUser);
  // const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  if (!isMobile && desktopCollapsed) {
    return (
      <AppShell.Navbar
        // hiddenBreakpoint="md"
        // fixed={false}
        // style={{
        //   backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
        // }}
        hidden={!opened}
        w={80}
      >
        <Group
          gap={0}
          px="md"
          align="center"
          justify="space-between"
          wrap="nowrap"
          my="md"
        >
          <Logo
            height={35}
            darkTheme={colorScheme === "dark"}
            iconOnly={!isMobile && desktopCollapsed}
          />
          {/* <IconChevronRight style={{ marginLeft: 5 }} onClick={desktopHandlers.toggle} /> */}
        </Group>
        <ScrollArea
          mt={3}
          p={5}
          style={{
            flex: 1,
          }}
        >
          <Stack gap={0}>
            {navBarLinks?.map((link) => (
              <>
                <Tooltip label={link.label} withinPortal position="right">
                  <NavLink
                    key={`parent${link.label}`}
                    to={link.href}
                    component={Link}
                    variant="subtle"
                    {...(link.icon && {
                      leftSection: (
                        <ThemeIcon variant="light" size="lg">
                          <link.icon size={ICON_SIZE} stroke={1.5} />
                        </ThemeIcon>
                      ),
                    })}
                    {...(link.description && { description: link.description })}
                    defaultOpened={
                      link.links?.find((childLink) =>
                        matchPath(
                          `${childLink?.href}/`,
                          location.pathname.replace(/\/[0-9]+|(new)/, ""),
                        ),
                      ) !== undefined
                    }
                    childrenOffset={40}
                    active={
                      link.links?.find((childLink) =>
                        matchPath(
                          `${childLink?.href}/`,
                          location.pathname.replace(/\/[0-9]+|(new)/, ""),
                        ),
                      ) !== undefined ||
                      link.routes?.find((route) =>
                        matchPath(`${route}/*`, location.pathname),
                      ) !== undefined
                    }
                  />
                </Tooltip>
                {link.links &&
                  link.links.map((childLink) => (
                    <Tooltip
                      label={childLink.label}
                      withinPortal
                      position="right"
                    >
                      <NavLink
                        key={`child-${link.id}-${childLink.label}`}
                        style={{
                          borderRadius: 5,
                        }}
                        {...(link.icon && {
                          leftSection: (
                            <ThemeIcon variant="light" size="lg">
                              <link.icon size={ICON_SIZE} stroke={1.5} />
                            </ThemeIcon>
                          ),
                        })}
                        to={childLink.href!}
                        component={Link}
                        active={
                          matchPath(
                            `${childLink?.href}/`,
                            location.pathname.replace(/\/[0-9]+|(new)/, ""),
                          ) !== null ||
                          link.routes?.find((route) =>
                            matchPath(`${route}/*`, location.pathname),
                          ) !== undefined
                        }
                      />
                    </Tooltip>
                  ))}
              </>
            ))}
          </Stack>
        </ScrollArea>
        <Stack style={{ gap: 0, borderTop: "1px solid #393945" }}>
          {/* <UserButton /> */}
          <Code
            fz="9px"
            style={{ fontWeight: "normal", textAlign: "center" }}
            mt={0}
          >
            {`${environment} - v${version}`}
          </Code>
        </Stack>
      </AppShell.Navbar>
    );
  }
  return (
    <AppShell.Navbar
      // hiddenBreakpoint="md"
      // fixed={false}
      // style={{
      //   backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      // }}
      hidden={!opened}
      // width={{ md: 275 }}
    >
      <Group
        px="md"
        align="center"
        justify="space-between"
        wrap="nowrap"
        my="md"
      >
        <Logo height={35} darkTheme={colorScheme === "dark"} />
        {/* <IconChevronLeft onClick={desktopHandlers.toggle} /> */}
      </Group>
      <ScrollArea
        mt={3}
        p={5}
        style={{
          flex: 1,
        }}
      >
        {navBarLinks?.map((link) =>
          link.links ? (
            <NavLink
              style={{
                children: {
                  // borderLeft: `1px solid ${
                  //   colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5]
                  // }`,
                },
                // color: theme.colors.gray[0],
                // ':active': {
                //   color: theme.colors.gray[1],
                // },
              }}
              key={`parent${link.label}`}
              to={link.href}
              component={Link}
              variant="subtle"
              label={link.label}
              {...(link.icon && {
                leftSection: <link.icon size={ICON_SIZE} stroke={1.5} />,
              })}
              {...(link.description && { description: link.description })}
              defaultOpened={
                link.links.find((childLink) =>
                  matchPath(
                    `${childLink?.href}/`,
                    location.pathname.replace(/\/[0-9]+|(new)/, ""),
                  ),
                ) !== undefined
              }
              childrenOffset={40}
              active={
                link.links.find((childLink) =>
                  matchPath(
                    `${childLink?.href}/`,
                    location.pathname.replace(/\/[0-9]+|(new)/, ""),
                  ),
                ) !== undefined ||
                link.routes?.find((route) =>
                  matchPath(`${route}/*`, location.pathname),
                ) !== undefined
              }
              // styles={{
              //   children: {
              //     borderLeft: `1px solid ${
              //       theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5]
              //     }`,
              //     paddingLeft: 0,
              //     marginLeft: 25,
              //   },
              // }}
            >
              {link.links &&
                link.links.map((childLink) => (
                  <NavLink
                    key={`child-${link.id}-${childLink.label}`}
                    style={{
                      borderRadius: 5,
                      children: {
                        // borderLeft: `1px solid ${
                        //   colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5]
                        // }`,
                      },
                      // color: theme.colors.gray[0],
                      // ':active': {
                      //   color: theme.colors.gray[1],
                      // },
                    }}
                    to={childLink.href!}
                    component={Link}
                    label={childLink.label}
                    {...(childLink.description && {
                      description: childLink.description,
                    })}
                    description={childLink.description}
                    active={
                      matchPath(
                        `${childLink?.href}/`,
                        location.pathname.replace(/\/[0-9]+|(new)/, ""),
                      ) !== null ||
                      link.routes?.find((route) =>
                        matchPath(`${route}/*`, location.pathname),
                      ) !== undefined
                    }
                  />
                ))}
            </NavLink>
          ) : (
            <NavLink
              style={{
                borderRadius: 5,
                // color: theme.colors.gray[0],
              }}
              key={`parent-${link.label}`}
              to={link.href!}
              component={Link}
              label={link.label}
              {...(link.description && { description: link.description })}
              {...(link.icon && {
                leftSection: <link.icon size={ICON_SIZE} stroke={1.5} />,
              })}
              description={link.description}
              childrenOffset={28}
              active={
                matchPath(`${link?.href}/*`, location.pathname) !== null ||
                link.routes?.find((route) =>
                  matchPath(`${route}/*`, location.pathname),
                ) !== undefined
              }
            />
          ),
        )}
      </ScrollArea>
      <Stack style={{ gap: 0, borderTop: "1px solid #393945" }}>
        <UserButton />
        <Code style={{ fontWeight: "bold", textAlign: "center" }} mt={0}>
          {`${environment} - v${version}`}
        </Code>
      </Stack>
    </AppShell.Navbar>
  );
}

const subcontractorLinks: NavbarLinksProps[] = [
  {
    icon: IconLayoutDashboard,
    href: "/",
    label: i18n.t("navigation.dashboard"),
  },
  {
    icon: IconMapPin,
    href: "/visits",
    label: i18n.t("navigation.visits"),
  },
  {
    icon: IconCalendar,
    href: "/scheduling",
    label: i18n.t("navigation.scheduling"),
  },
  {
    icon: IconHomeSearch,
    href: "/jobs",
    label: i18n.t("navigation.jobs", { count: 2 }),
  },
  {
    icon: IconChartTreemap,
    href: "/plans",
    label: i18n.t("navigation.plan", { count: 2 }),
  },
  // {
  //   icon: IconCalendarTime,
  //   label: i18n.t('navigation.timesheets'),
  //   href: '/timesheets',
  // },
  // {
  //   icon: IconCalendar,
  //   label: i18n.t('navigation.scheduling'),
  //   href: '/scheduling',
  // },
  {
    icon: IconSettings,
    label: "Administration",
    href: "/administration",
    id: "administration",
    routes: ["/org-admin", "/users", "/users/roles"],
  },
];
const sublinxAdminLinks: NavbarLinksProps[] = [
  {
    icon: IconSettings,
    label: i18n.t("navigation.sublinxAdministration.title"),
    href: "/admin",
    color: "orange",
    id: "admin",
    expanded: false,
    links: [
      // {
      //   label: i18n.t('navigation.sublinxAdministration.mobileApp'),
      //   description: 'View and test the mobile app',
      //   href: '/admin/mobile',
      // },
      {
        label: "Subcontractors",
        description: "Create and manage subcontractors",
        href: "/admin/subcontractors",
      },
      {
        label: "Builders",
        description: "Create and manage builders",
        href: "/admin/builders",
      },
      {
        label: "Trades & Tasks",
        description: "Create and manage trades and tasks",
        href: "/admin/trades",
      },
      {
        label: "Reorder Tasks",
        description: "Reorder trades and tasks",
        href: "/admin/tasks",
      },
      {
        label: "Users",
        description: "View Keycloak Users",
        href: "/admin/users",
      },
    ],
  },
];

const builderLinks: NavbarLinksProps[] = [
  {
    icon: IconLayoutDashboard,
    href: "/",
    label: i18n.t("navigation.dashboard"),
  },
  {
    icon: IconMapPin,
    href: "/visits",
    label: i18n.t("navigation.visits"),
  },
  // {
  //   icon: IconCalendar,
  //   href: '/scheduling',
  //   label: i18n.t('navigation.scheduling'),
  // },
  {
    icon: IconCalendar,
    href: "/schedules",
    id: "schedules-main",
    label: i18n.t("navigation.build-schedule", { count: 2 }),
    links: [
      {
        href: "/schedules/stages",
        label: i18n.t("navigation.stage", { count: 2 }),
      },
      {
        href: "/schedules",
        description: "Plan Schedules",
        label: i18n.t("navigation.schedule", { count: 2 }),
      },
    ],
  },
  {
    icon: IconHome,
    href: "/jobs",
    label: i18n.t("navigation.jobs"),
    links: [
      {
        href: "/jobs/dashboard",
        label: i18n.t("navigation.job-dashboard", { count: 2 }),
      },
      {
        href: "/jobs/filtered",
        label: i18n.t("navigation.job-list", { count: 2 }),
      },
    ],
  },
  {
    icon: IconBuildingSkyscraper,
    label: i18n.t("navigation.subdivision", { count: 2 }),
    href: "/projects",
    id: "project-main",
    expanded: true,
    links: [
      {
        href: "/subdivisions",
        label: i18n.t("navigation.subdivision", { count: 2 }),
      },
      {
        href: "/plans",
        label: i18n.t("navigation.plan", { count: 2 }),
      },
    ],
  },
  {
    icon: IconUser,
    label: "Users",
    href: "/users",
    expanded: false,
    links: [
      {
        label: "Users",
        description: "Find and manage users",
        href: "/users",
      },
      {
        label: "Roles",
        description: "Find and manage roles",
        href: "/users/roles",
      },
    ],
  },
  {
    icon: IconUsersGroup,
    label: "Subcontractors",
    href: "/organization/subcontractors",
    expanded: false,
  },
];
function fetchNavBarLinks(user?: UserType): NavbarLinksProps[] {
  const links: NavbarLinksProps[] = [];
  if (user?.orgType === "BUILDER") {
    links.push(...builderLinks);
  }
  if (user?.orgType === "SUB") {
    links.push(...subcontractorLinks);
  }

  if (keycloak.tokenParsed?.realm_access?.roles.includes("SUBLINX_ADMIN")) {
    links.push(...sublinxAdminLinks);
  }

  return links;
}
