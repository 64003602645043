import React from 'react';
import { UnstyledButton, UnstyledButtonProps, Group, Avatar, Text, Indicator } from '@mantine/core';
import { useKeycloak } from '@react-keycloak/web';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useIdle } from '@mantine/hooks';

import classes from './UserButton.module.css';

interface UserButtonProps extends UnstyledButtonProps {
  icon?: React.ReactNode;
}

export function UserButton({ icon, ...others }: UserButtonProps) {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const idle = useIdle(10 * 60 * 1000, {
    initialState: false,
  });
  return (
    <UnstyledButton
      p="sm"
      className={classes.user}
      {...others}
      onClick={() => navigate(`/users/${keycloak?.tokenParsed?.user_id}`)}
    >
      <Group>
        <Indicator color={idle ? 'yellow' : 'teal'}>
          <Avatar color="cyan" radius="md">
            {`${keycloak?.tokenParsed?.given_name?.charAt(
              0
            )}${keycloak?.tokenParsed?.family_name?.charAt(0)}`}
          </Avatar>
        </Indicator>

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {`${keycloak?.tokenParsed?.given_name} ${keycloak?.tokenParsed?.family_name}`}
          </Text>

          <Text c="dimmed" size="xs">
            {keycloak?.tokenParsed?.email}
          </Text>
        </div>

        {icon || <IconChevronRight size={14} />}
      </Group>
    </UnstyledButton>
  );
}
